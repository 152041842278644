import * as React from "react"
import Header from "./header"
import Footer from "./footer"
import styled from "styled-components"
import WhatsAppButton from "../buttons/whatsApp"
//import GoldenVisaButton from "../buttons/goldenVisa"

const FloatingButtons = styled.div`
    position: fixed;
    right: 1%;
    bottom: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 140px;
`

const Layout = ({children}) => {
    return (
        <div style={{width: '100vw', overflowX: 'hidden'}}>
            <Header />
                {children}
            <Footer />
            <FloatingButtons>
                <WhatsAppButton/>
            </FloatingButtons>
        </div>
    )
}

export default Layout