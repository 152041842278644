import React from "react"
import styled from "styled-components"
import "../../../css/typography.css"
import WhatsApp from "../../../images/layout/Whats.webp"
import { Link } from "gatsby"

const Button = styled.button`
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: black;
    border-radius: 12px;
    color: black;
    border: none;

    a p {
        font-family: "A Medium";
        font-size: 25px;
        color: white;
    }
    a {
        text-decoration: none;
        margin-right: 5%;
    }
    a img {
        width: 70%;
    }
  @media screen and (min-width: 1200px){
      width: 230px;
      height: 90px;
      margin-bottom:20px;
      border-radius: 26px;
      a{
        margin-right:0%;
      }
      a p{
        font-size: 30px;
      }
      a img{
        width: 50%;
        margin-right:1px;
      }
  }

  @media screen and (min-width: 1800px) {
      width: 250px;
      height: 90px;
      margin-bottom:20px;
      border-radius: 26px;

    a p{
      font-size: 30px;
    }
    a img{
      width: 80%;
    }
  }

  @media screen and (min-width: 3500px) {
      width: 350px;
      height: 190px;
      margin-bottom:20px;

    a p{
      font-size: 43px;
    }
    a img{
      width: 80%;
    }
  }
  @media screen and (max-width: 1200px){
    width:150px;
    height:60px;
    
    a p {
      font-size: 18px;
    }
    a img {
      width: 30px;
    }
  }

`

const IndexPage = () => {

    return (
        <Button>
            <Link href='https://wa.me/525615084111' target="_blank"><img src={WhatsApp} alt="WhatsApp Icon" /></Link>
            <Link href='https://wa.me/525615084111' target="_blank"><p>WhatsApp</p></Link>
        </Button>
    )
}

export default IndexPage