import React from "react"
import styled from "styled-components"
import "../../../css/typography.css"

const Button = styled.div`
    background-color: #49BAEA;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    padding: 8% 8% 8% 8% !important;
    border-radius: 9px;
    margin-top: 10%;
    cursor: pointer;

    a {
        text-decoration: none;
        font-family: 'A Heavy';
        color: #FFFFFF;
        font-size: 20px;
        text-align: center;
    }
    @media only screen and (min-width: 1500px){
        width:180px;
        height:70px;

        a{
            font-size: 25px;
        }
    }
    @media only screen and (min-width: 1800px){
        width:200px;
        height:70px;

        a{
            font-size: 30px;
        }
    }
    @media only screen and (min-width: 2500px){
        width:300px;
        height:80px;

        a{
            font-size: 40px;
        }
    }
    @media only screen and (min-width: 3500px){
        width: 400px;
        height: 100px;

        a{
            font-size: 50px;
        }
    }
    @media only screen and (min-width: 4000px){
        width: 500px;
        height: 130px;

        a{
            font-size: 60px;
        }
    }
    @media only screen and (max-width: 910px) {
        a {
            font-size: 15px;
        }
    }
`

const AppButton = () => {

    return  (
        <Button>
            <a href="https://www.ventumsupervision.com/login">Iniciar sesión</a>
        </Button>
    )
        
}

export default AppButton
