import * as React from "react"
import Logo from "../../images/layout/Logo.webp"
import styled from "styled-components"
import Mail from "../../images/layout/Mail.webp"
import Location from "../../images/layout/Location.webp"
import Whats from "../../images/layout/Whats.webp"
import "../../css/typography.css"
import { Link } from "gatsby"

const FooterTag = styled.footer`
    background-color: #092247;
    width: 100vw;
    overflow-x: hidden;
`

const MainDiv = styled.div`
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 15px;
    align-items: center;

    @media only screen and (max-width: 480px) {
        font-size: 5vw;
    }
`

const HorizontalDiv = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 4% 2% 2% 2%;

    div {
        width: 33.33vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    div > p {
        width: 300px;
        text-align: center;
        margin-top: 0px;
    }

    img {
        width: 50px;
        margin-bottom: 15px;
    }

    div > p:nth-child(2) {
        font-family: 'A Black';
        font-size: 19px;
        margin-bottom: 0;
    }
    a {
        color: white;
        text-decoration: none;
        font-family: 'A Regular';
        font-size: 17px;
    }
    @media only screen and (min-width: 1200px) { 
        img {
            width: 50px;
        }
        div > p:nth-child(2) {
            font-size: 25px;
        }
        a {
            font-size: 20px;
        }
    }
    @media only screen and (min-width: 1500px) { 
        img {
            width: 60px;
        }
        div > p:nth-child(2) {
            font-size: 30px;
        }
        div > p {
            width:400px;
        }
        a {
            font-size: 25px;
        }
    }
    @media only screen and (min-width: 1800px) { 
        img {
            width: 80px;
        }
        div > p:nth-child(2) {
            font-size: 35px;
        }
        div > p {
            width:500px;
        }
        a {
            font-size: 30px;
        }
    }
    @media only screen and (min-width: 2500px) { 
        img {
            width: 90px;
        }
        div > p:nth-child(2) {
            font-size: 45px;
        }
        div > p {
            width:600px;
        }
        a {
            font-size: 40px;
        }
    }
    @media only screen and (min-width: 3500px) { 
        img {
            width: 130px;
        }
        div > p:nth-child(2) {
            font-size: 55px;
        }
        div > p {
            width:800px;
        }
        a {
            font-size: 50px;
        }
    }
    @media only screen and (min-width: 4000px) { 
        img {
            width: 170px;
        }
        div > p:nth-child(2) {
            font-size: 65px;
        }
        div > p {
            width:92 0px;
        }
        a {
            font-size: 60px;
        }
    }
    @media only screen and (max-width: 905px) { 
        a {
            font-size: 15px;
        }
        div > p:nth-child(2) {
            font-size: 17px;
        }
    }

    @media only screen and (max-width: 840px) { 
        div > p {
            width: 200px;
        }
    }

    @media only screen and (max-width: 560px) {
        width: 100vw;
        flex-direction: column;
        align-items: center;
        line-height: 5vw;

        div {
            width: 100%;
            margin-bottom: 5vw;
        }
        a {
            font-size: 20px;
        }
        div > p:nth-child(2) {
            font-size: 22px;
            margin-bottom: 5px;
        }
        div p {
            width: 100%;
        }
    }
`

const DerechosDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 15px;
    font-family: 'A Regular';
    text-align: center;

    >div:nth-child(1) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 55%;
    }

    a {
        color: white;
    }
    @media only screen and (min-width: 1200px) { 
        >div:nth-child(1){
            width: 500px;
        }
        a {
            font-size: 25px;
        }
        p {
            font-size: 20px;
        }
    }
    @media only screen and (min-width: 1500px) { 
        >div:nth-child(1){
            width: 600px;
        }
        a {
            font-size: 30px;
        }
        p {
            font-size: 25px;
        }
    }
    @media only screen and (min-width: 1800px) { 
        >div:nth-child(1){
            width: 700px;
        }
        a {
            font-size: 30px;
        }
        p {
            font-size: 25px;
        }
    }
    @media only screen and (min-width: 2500px) { 
        >div:nth-child(1){
            width: 900px;
        }
        a {
            font-size: 40px;
        }
        p {
            font-size: 35px;
        }
    }
    @media only screen and (min-width: 3500px) { 
        >div:nth-child(1){
            width: 1100px;
        }
        a {
            font-size: 55px;
        }
        p {
            font-size: 50px;
        }
    }
    @media only screen and (min-width: 4000px) { 
        >div:nth-child(1){
            width: 1300px;
        }
        a {
            font-size: 65px;
        }
        p {
            font-size: 60px;
        }
    }
    @media only screen and (max-width: 480px) {
        padding: 6vw;
        font-size: 19px;

        >div:nth-child(1) {
            width: 100%;
        }
    }
`

const Separator = styled.div`
    border-right: 1px solid white;
    height: 20px;
    width: 1px;

    @media only screen and (min-width: 1200px){
        height: 30px;
    }
    @media only screen and (min-width: 1500px){
        height: 60px;
    }
    @media only screen and (min-width: 2500px){
        height: 70px;
    }
    @media only screen and (min-width: 3500px){
        height: 100px;
    }
    @media only screen and (min-width: 4000px){
        height: 120px;
    }
`

const MainSeparator = styled.div`
    border-bottom: .5px solid white;
    height: 1px;
    width: 90vw;
    margin-bottom: 10px;
`

const Footer = () => {
    return (
        <FooterTag>
            <MainDiv>
                <HorizontalDiv>
                    <div>
                        <img src={Mail} alt="Mail" />
                        <p>Correo</p>
                        <p><a href="mailto:contacto@ventumsupervision.com">contacto@ventumsupervision.com</a></p>
                    </div>
                    <div>
                        <img src={Whats} alt="Whats" />
                        <p>Teléfono</p>
                        <p><a href="tel:5615084111">5615084111 </a></p>
                    </div>
                    {/* <div>
                        <img src={Location} alt="Location" className="dir-image" />
                        <p>Dirección</p>
                        <p><a href="">Ejército Nacional Este, Avenida General Mariano Escobedo Oeste</a></p>
                    </div> */}
                </HorizontalDiv>
                <MainSeparator />
                <DerechosDiv>
                    <div>
                        <p><Link to='/aviso-de-privacidad'>Aviso de Privacidad</Link></p>
                        <Separator />
                        <p><Link to='/cookies'>Políticas de Cookies</Link></p>
                    </div>
                    <p>©2022 Ventum. Todos los derechos reservados. Elaborado por <a href="https://www.ygucomunicaxion.com" target='_blank'>YGU Comunicaxión</a></p>
                </DerechosDiv>
            </MainDiv>
        </FooterTag>
    )
}

export default Footer
