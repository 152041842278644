import React, {useState} from "react"
import Logo from "../../images/layout/Logo.webp"
import { Link } from "gatsby"
import styled from "styled-components"
import "../../css/typography.css"
import useWindowSize from "../../hooks/useWindowSize"
import { MenuOutlined } from '@ant-design/icons';
import ButtonApp from "../buttons/app"

const HeaderTag = styled.header`
    display: flex;
    align-items: center;

    >div:nth-child(1) {
        width: 15%;
    }

    >div:nth-child(3) {
        width: 10%;
        margin-left: 22%;
    }

    nav {
        display: flex;
        justify-content: space-between;
        width: 33%;
        padding: 3% 5% 2% 2%;
    }
    nav a {
        font-family: 'A Regular';
        color: #092247;
        text-decoration: none;
        font-size: 20px;
    }
    div img {
        width: 80%;
    }
    div {
        padding: 1% 1% 1% 3%;
    }

    @media only screen and (min-width: 1500px){
        >div:nth-child(1) {
        margin-right: 80px;;
        }
        div img {
            width: 250px;
        }
        nav a {
            font-size: 30px;
        }
        >div:nth-child(3) {
        width: 10%;
        margin-left: 15%;
    }

    }
    @media only screen and (min-width: 1800px){
        >div:nth-child(1) {
        margin-right: 100px;;
        }
        div img {
            width: 300px;
        }
        nav a {
            font-size: 40px;
        }
        nav {
            width: 700px;
        }
        >div:nth-child(3) {
        margin-left: 12%;
        }
    }
    @media only screen and (min-width: 2500px){
        >div:nth-child(1) {
        margin-right: 100px;;
        }
        div img {
            width: 400px;
        }
        nav a {
            font-size: 50px;
        }
        nav {
            width: 900px;
        }
        >div:nth-child(3) {
        margin-left: 16%;
        }
    }
    @media only screen and (min-width: 3500px){
        >div:nth-child(1) {
        margin-right: 140px;;
        }
        div img {
            width: 600px;
        }
        nav a {
            font-size: 70px;
        }
        nav {
            width: 1200px;
        }
        >div:nth-child(3) {
        margin-left: 18%;
        }
    }
    @media only screen and (min-width: 4000px){
        >div:nth-child(1) {
        margin-right: 300px;;
        }
        div img {
            width: 700px;
        }
        nav a {
            font-size: 80px;
        }
        nav {
            width: 1400px;
        }
        >div:nth-child(3) {
        margin-left: 13%;
        }
    }
    @media only screen and (max-width: 752px) {

        div img {
            width: 90%;
        }
        div {
            padding: 3% 3% 3% 8%;
        }
        >div:nth-child(2) {
            width: 32%;
            padding-left: 5%;
        }
    }
    @media only screen and (max-width: 910px) {
        nav a {
            font-size: 15px;
        }
    }

    @media only screen and (max-width: 1010px) {
        >div:nth-child(3) {
            margin-left: 18%;
        }
    }

    .selected-menu-item {
        color: #092247;
        font-family: 'A Black';
    }
`

const HeaderTagMov = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;

    >div:nth-child(2) {
        width: 15%;
    }

    div img {
        width: 80%;
    }
    div {
        padding: 1% 1% 1% 3%;
    }

    @media only screen and (max-width: 752px) {

        div img {
            width: 90%;
        }
        div {
            padding: 3% 3% 3% 8%;
        }
        >div:nth-child(1) {
            width: 32%;
            padding-left: 5%;
        }
    }
    @media only screen and (max-width: 910px) {
        nav a {
            font-size: 15px;
        }
    }

    .selected-menu-item {
        color: #092247;
        font-family: 'Avenir Black';
    }
`

const MobileNav = styled.nav`
    display: flex;
    flex-direction: column;
    background-color: #092247;
    padding: 5% 0% 3% 5%;

    a {
        text-decoration: none;
        color: white;
        font-family: 'A Regular';
        font-size: 18px;
        margin-bottom: 10px;
    }

    .selected-menu-item {
        font-family: 'Avenir Black';
    }
`

const Header = () => {
    const windowSize = useWindowSize();
    const [nav, setNav] = useState(false)

    return windowSize > 752 ? (
        <HeaderTag>
            <div>
                <Link to='/'>                
                    <img src={Logo} alt="logo" />
                </Link>
            </div>
            <nav>
                <Link to='/' className={window.location.pathname === "/" ? "selected-menu-item": ""}>Inicio</Link>
                <Link to='/nuestro-sistema' className={window.location.pathname === "/nuestro-sistema" ? "selected-menu-item": ""}>Nuestro Sistema</Link>
                <Link to='/contacto' className={window.location.pathname === "/contacto" ? "selected-menu-item": ""}>Contacto</Link>
            </nav>
            <div>
                <ButtonApp />
            </div>
        </HeaderTag>
    ) : (
        <div>
        <HeaderTagMov>
            <div>
                <Link to='/'>                
                    <img src={Logo} alt="logo" />
                </Link>            
            </div>
            <div>
                <MenuOutlined style={{color: '#092247', fontSize: '280%'}} nav={nav} onClick={() => setNav(!nav)} />
            </div>
        </HeaderTagMov>
        { nav ? (
            <MobileNav>
                <Link to='/' className={window.location.pathname === "/" ? "selected-menu-item": ""}>Inicio</Link>
                <Link to='/nuestro-sistema' className={window.location.pathname === "/nuestro-sistema" ? "selected-menu-item": ""}>Nuestro Sistema</Link>
                <Link to='/contacto' className={window.location.pathname === "/contacto" ? "selected-menu-item": ""}>Contacto</Link>
                <a href="https://www.ventumsupervision.com/login" target="_blank">Iniciar sesión</a>
            </MobileNav>
        ) : null }
        </div>
    )
}

export default Header
